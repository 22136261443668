import React, { useEffect, useRef, useState } from 'react';
import { clsx } from 'clsx';
import Trancelation from '../../audio/LGMX-Trancelation.mp3';
import GopnikMazurka from '../../audio/LGMX-GopnikMazurka.mp3';
import TimeTraveller from '../../audio/LGMX-TimeTraveller.mp3';
import Darius from '../../audio/LGMX-Darius.mp3';
import Kyushu from '../../audio/LGMX-Kyushu.mp3';

const AudioPlayer = () => {
  const [src, setSrc] = useState(null);
  const player = useRef();

  useEffect(() => {
   if (player.current) {
      if (src) {
        player.current.pause(); // Arrête toute lecture en cours
        player.current.load();  // Recharge la nouvelle source
        player.current.play();  // Joue la nouvelle piste
      } else {
        player.current.pause();
        player.current.currentTime = 0;
      }
    }
  }, src);

  const clickOnLine = (newSrc) => {
    if (newSrc === src) {
      setSrc(null);
    } else {
      setSrc(newSrc);
    }
  };

  return (
    <>
      <button type="button" onClick={() => clickOnLine(Trancelation)} className={clsx(src === Trancelation && 'isPlaying')}>
        <div>LGMX - Trancelation</div>
        <div className="audioContainer-progress" style={{ width: '0%' }}>&nbsp;</div>
      </button>
      <button type="button" onClick={() => clickOnLine(Kyushu)} className={clsx(src === Kyushu && 'isPlaying')}>
        <div>LGMX - Kyushu</div>
        <div className="audioContainer-progress" style={{ width: '0%' }}>&nbsp;</div>
      </button>
      <button type="button" onClick={() => clickOnLine(TimeTraveller)} className={clsx(src === TimeTraveller && 'isPlaying')}>
        <div>LGMX - Time Traveller</div>
        <div className="audioContainer-progress" style={{ width: '0%' }}>&nbsp;</div>
      </button>
      <button type="button" onClick={() => clickOnLine(GopnikMazurka)} className={clsx(src === GopnikMazurka && 'isPlaying')}>
        <div>LGMX - Gopnik Mazurka</div>
        <div className="audioContainer-progress" style={{ width: '0%' }}>&nbsp;</div>
      </button>
      <button type="button" onClick={() => clickOnLine(Darius)} className={clsx(src === Darius && 'isPlaying')}>
        <div>LGMX - Darius (Live)</div>
        <div className="audioContainer-progress" style={{ width: '0%' }}>&nbsp;</div>
      </button>

      { src && (
        <audio id="audioContainer-player" ref={player}>
          <source src={src} type="audio/mpeg" />
        </audio>
      )}

    </>
  );
};

export default AudioPlayer;
