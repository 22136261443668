import React, { useState } from 'react';
import Popup from './Popup';
import shankara from '../../img/video_shankara.jpg';
import baleine from '../../img/video_baleine.jpg';
import gopnik from '../../img/video_gopnik.jpg';
import video_darius from '../../img/video_darius.JPG';
import video from '../../img/media_video.jpg';
import playbtn from '../../img/playbtn.svg';

const VideoCarousel = () => {
  const [curPage, setCurPage] = useState(0);
  const [curSelectedVideoCode, setCurSelectedVideoCode] = useState(null);

  return (
    <>
      { curSelectedVideoCode && (
      <Popup onClosePopup={() => setCurSelectedVideoCode(null)}>
        <iframe className="smallVideoContainer-iframe" src={`https://www.youtube.com/embed/${curSelectedVideoCode}?autoplay=1&showinfo=0&modestbranding=1&&rel=0&wmode=transparent&autohide=1`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
      </Popup>
      )}

      <div className="smallVideoContainer carousel" data-slide="0">
        { curPage > 0 && <button className="previousBtn" onClick={() => setCurPage(curPage - 1)} />}
        <div>
          <div style={{ position: 'relative', left: `-${100 * curPage}%` }}>
            <button className="smallVideo" onClick={() => setCurSelectedVideoCode('4W-6RC0CAis')}>
              <img src={gopnik} alt="la Video de Gopnik Mazurka composition HardBass" />
              <img src={playbtn} alt="la Video de Gopnik Mazurka composition HardBass" />
            </button>
            <div>Gopnik Mazurka</div>
          </div>
          <div style={{ position: 'relative', left: `-${100 * curPage}%` }}>
            <button className="smallVideo" onClick={() => setCurSelectedVideoCode('Gtg-2u9hCkQ')}>
              <img src={shankara} alt="la Video de Mahadeva psytrance astral projection" />
              <img src={playbtn} alt="la Video de Mahadeva psytrance astral projection" />
            </button>
            <div>Mahadeva</div>
          </div>

          <div style={{ position: 'relative', left: `-${100 * curPage}%` }}>
            <button className="smallVideo" onClick={() => setCurSelectedVideoCode('wezDyYgyjMI')}>
              <img src={video_darius} alt="la Video de Darius en live" />
              <img src={playbtn} alt="la Video de Darius en live" />
            </button>
            <div>Darius (live)</div>
          </div>

          <div style={{ position: 'relative', left: `-${100 * curPage}%` }}>
            <button className="smallVideo" onClick={() => setCurSelectedVideoCode('BMWpfG_Bpto')}>
              <img src={baleine} alt="la Video de movement of whale deep house" />
              <img src={playbtn} alt="la Video de movement of whale deep house" />
            </button>
            <div>Movement of Whale</div>
          </div>
          <div style={{ position: 'relative', left: `-${100 * curPage}%` }}>
            <button className="smallVideo" onClick={() => setCurSelectedVideoCode('ixBVMSl_YJI')}>
              <img src={video} alt="la Video de lgmx fanfare electro qui joue Small Talk de la psytrance" />
              <img src={playbtn} alt="la Video de lgmx fanfare electro qui joue Small Talk de la psytrance" />
            </button>
            <div>Small Talk</div>
          </div>
        </div>
        { curPage < 4 && <button className="nextBtn" onClick={() => setCurPage(curPage + 1)} /> }
      </div>
    </>
  );
};

export default VideoCarousel;
